import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { v4 } from 'uuid'
import Img from 'gatsby-image'
import { Section, SectionTitle, SectionDescription, SectionHeader, SectionPageTitle, MainPanel, MarkdownContent, BreadCrumb } from "../components/section"
import Layout from "../components/layout"
import SEO from "../components/seo"
import DefaultButton from "../components/button/DefaultButton"
import PhoneIcon from "../components/Icons/PhoneIcon"
import DoubleFrameBg from "../images/doubleframe.png"
import BreakpointUp from "../components/Media/BreakpointUp"
import generateHTML from '../utils/generateHTML';
import Content, { HTMLContent } from '../components/Content';

const SiteBgImage = styled.div`
 	background-image:${props => props.bgImg};
     display: block;
     height: 100%;
     width: 100%;
     position: absolute;
     top: 0px;
     left: 0;
     background-repeat: no-repeat;
     background-position: bottom center;
     right: 0;
     background-size: cover;
	&:after{
		content:'';
		position:absolute;
		top:0;
		right:0;
		bottom:0;
		left:0;
		background-color:rgba(255,255,255,0.8);
	}
`
const SectionCover = styled.div`  
  display: flex;
  flex-wrap:wrap;
  align-items: center;
  margin:0 -15px;    
  ${BreakpointUp.lg`                  
    padding-left: calc((100% - 1350px)/2);
  `}
`
const CoverFigure = styled.div`
  padding: 0 15px;
  width:100%;
  display:none;
  ${BreakpointUp.lg`   
    display:block;
    flex: 0 0 50%;
    max-width: 50%;
  `}
  .gatsby-image-wrapper {
    max-height: 500px;
    height: 100%;
  }
`
const CoverContent = styled.div`
  position:relative;
  padding:0 15px;
  width:100%;
  ${BreakpointUp.lg`          
    flex: 0 0 50%;
    max-width: 50%;
  `}
  & p{
    margin-bottom:30px;
  }
`
const Considerations = styled.div`
    display: grid;
    grid-column-gap: 30px;   
    grid-template-columns: repeat(1,1fr);    
    ${BreakpointUp.sm`
    grid-template-columns: repeat(2,1fr);
	`}
    ${BreakpointUp.lg`
        grid-template-columns: repeat(4,1fr);
    `}
`
const ConsiderationsItem = styled.div`
    text-align:center;
    margin-bottom:30px;
    position:relative;
    padding-top: 30px;
    ${BreakpointUp.sm`
        padding-top: 0;
    `}
    &:before{
        content: '';
        background-color: #ddd;
        display: block;
        position: absolute;
        top: 0;
        height: 1px;
        width: 100%;
        left:0;
        right:0;
        ${BreakpointUp.sm`
            height: 100%;
            width: 1px;
            left: -15px;
            right:auto;
        `}
    } 
    &:first-child{
        &:before{
            display:none;
        }
    }
   
`
const ConsiderationsIcon = styled.div`
   margin-bottom:5px;
   & svg{
    fill:#666;
   }
   
`
const ConsiderationsText = styled.div`
    & h3{
       font-size:20px;
       font-weight:700;
       font-family: 'Roboto', serif;
       line-height:28px;
       margin-bottom:5px;
   }
    & p{
        margin:0;
   }
   
`

const SitePreparationPage = ({data, location}) => {
  const pageData = data.contentfulSitePreparationPage
  const PostContent = HTMLContent || Content;
  return(
    <Layout location={location}>
      <SEO title={pageData.metaTitle} description={pageData.metaDescription}/>    
      <SectionHeader pt="140px" pb="90px" xpt="140px" xpb="60px" bgColor="linear-gradient(to right,  #f6f6f6 0%,#f6f6f6 75%,#eaeaea 75%,#eaeaea 100%)">
        <div className="container">
          <SectionCover>
            <CoverContent>
              <BreadCrumb><Link to='/'>Home</Link>/<span>Site Preparation</span></BreadCrumb> 
              <SectionPageTitle mt="30px" mb="15px" textAlign="left">{pageData.heroTitle}</SectionPageTitle>
              <SectionDescription mb="50px" textAlign="left">
                <PostContent
                  content={generateHTML(pageData.heroDescription.childMarkdownRemark.html)}
                />
              </SectionDescription>            
            </CoverContent>  
            <CoverFigure>
              <Img fluid={pageData.image.fluid} />
            </CoverFigure>
          </SectionCover>
        </div>
      </SectionHeader>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px">
        <SiteBgImage bgImg={`url(${DoubleFrameBg})`} />
        <div className="container">
          <SectionTitle>{pageData.secondSectionTitle}</SectionTitle>
          <SectionDescription mb="50px" maxWidth="890px">
            {pageData.secondSectionDescription.secondSectionDescription}
          </SectionDescription> 
          <Considerations>
            {
              pageData.features.map(item=>{
                return(
                  <ConsiderationsItem key={v4()}>
                    <ConsiderationsIcon>
                      <img src={item.icon} alt={item.feature} />
                    </ConsiderationsIcon>
                    <ConsiderationsText>                        
                        <h3>{item.feature}</h3>
                        <p>{item.description}</p>
                    </ConsiderationsText>
                  </ConsiderationsItem>
                )
              })
            }
          </Considerations>
        </div>
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px">
        <div className="container">
          <MainPanel>
            <MarkdownContent pl="15px" pr="15px" maxWidth="1120px">
              <PostContent
                content={generateHTML(pageData.content.childMarkdownRemark.html)}
              />
              <p><a to='tel:8776005980'><DefaultButton icon={<PhoneIcon />} text="(877) 600-5980" /></a></p>
            </MarkdownContent>
          </MainPanel>
        </div>
      </Section>
    </Layout>
  )
} 
export default SitePreparationPage

export const pageQuery = graphql`
  query SitePreparationPageQuery {
    contentfulSitePreparationPage {
      metaTitle
      metaDescription
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      image {
        fluid {
          aspectRatio
          base64
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
      secondSectionTitle
      secondSectionDescription {
        secondSectionDescription
      }
      features {
        feature
        description
        icon
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`